import React, { FC, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import ContactBlock from "@components/atoms/ContactBlock/ContactBlock";
import { arrowRightIcon, mailIcon, markerIcon, phoneIcon } from "@assets/icons";
import Input from "@components/atoms/Input/Input";
import Textarea from "@components/atoms/Textarea/Textarea";
import Checkbox from "@components/atoms/Checkbox/Checkbox";
import Button from "@components/atoms/Button/Button";
import ReCaptcha from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import axios from "axios";
import { cssEaseOutExpo } from "@theme/easing";
import { graphql, useStaticQuery } from "gatsby";

const StyledWrapper = styled.section`
  margin-top: 200px;
  background: linear-gradient(
    183.58deg,
    #191e1c -84.49%,
    #191e1c -11.74%,
    #1e3430 57.35%,
    #244b45 158.73%,
    #285a53 218.74%
  );
  color: #fff;
  padding: 90px 0 60px;

  @media (max-width: 840px) {
    margin-top: 100px;
  }
`;

const StyledInnerWrapper = styled.div`
  max-width: 1450px;
  margin: auto;
  width: 85%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 840px) {
    width: 90%;
    max-width: 600px;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLeftColumn = styled.div`
  max-width: 500px;

  @media (max-width: 840px) {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledRightColumnn = styled.div`
  max-width: 514px;
  margin-left: 40px;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    max-width: 700px;
  }
`;

const StyledHeadline = styled.h2`
  font-size: 4.8rem;
  line-height: 1.4;
  margin: 0 0 50px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 3.2rem;
  }

  @media (max-width: 840px) {
    text-align: center;
  }
`;

const StyledContactBlock = styled(ContactBlock)`
  margin-left: 60px;
  margin-bottom: 25px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  ${({ headline }) =>
    headline === "Biuro sprzedaży" &&
    css`
      > div {
        max-width: 170px;
      }

      svg {
        flex-shrink: 0;
      }
    `}
`;

const StyledSecondHeadline = styled.h3`
  font-family: "Cardo", serif;
  font-size: 3.4rem;
  font-weight: 400;
`;

const StyledForm = styled.form``;

const StyledBottomFormWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;

  @media (max-width: 1240px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledInput = styled(Input)`
  width: 375px;
  margin-bottom: 28px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledTextarea = styled(Textarea)`
  width: 375px;
  height: 100px;

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 50px;
    `};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 100px;

  div:first-of-type {
    span {
      border: 1px solid ${({ theme }) => theme.washLight};
      svg {
        color: transparent;
      }
    }

    input:checked ~ span {
      background: ${({ theme }) => theme.washLight};

      svg {
        color: ${({ theme }) => theme.primaryDarker};
      }
    }
  }

  div:last-of-type {
    font-size: 1rem;
    max-width: 250px;
  }

  @media (max-width: 1240px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
`;

const StyledReCaptchaWrapper = styled.div`
  margin: 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  div {
    margin: 0 !important;
  }

  span {
    font-size: 1.2rem;
    color: #ff6a6a;
    margin-top: 10px;
    display: block;
  }

  iframe {
    transform-origin: left top;
  }

  @media (max-width: 370px) {
    margin: 30px 0 10px;
    iframe {
      transform: scale(0.8);
    }
  }
`;

const StyledButton = styled(Button)`
  div {
    font-size: 1.6rem;
  }

  @media (max-width: 540px) {
    margin: auto;
  }
`;

const lineAnimation = keyframes`
  0% {
    transform: scaleX(0);
    
  }
  100% {
    transform: scaleX(1);
  }
`;

const AfterSendMessage = styled.div<{ $isSuccess: boolean }>`
  color: ${({ $isSuccess }) => ($isSuccess ? "#fff" : "#ff6a6a")};
  text-align: center;
  font-size: 1.8rem;
  position: relative;
  font-weight: 500;
  margin-top: 120px;
  display: inline-block;

  ::before {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    transform-origin: top left;
    animation: 2s ${cssEaseOutExpo} ${lineAnimation};
    left: 0;
    bottom: -12px;
    background: ${({ $isSuccess }) => ($isSuccess ? "#fff" : "#ff6a6a")};
    border-radius: 40px;
  }

  @media (max-width: 840px) {
    margin-top: 80px;
  }

  @media (max-width: 650px) {
    font-size: 1.6rem;
  }
`;

const Contact: FC = () => {
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [wasSendTry, setWasSendTry] = useState<boolean>(false);
  const [successfulSend, setSuccessfulSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { datoCmsSetting } = useStaticQuery<{
    datoCmsSetting: {
      eMail: string;
      phoneNumber: string;
      address: string;
      addressLink: string;
    };
  }>(query);

  const onSubmit = (data: {
    name: string;
    email: string;
    phoneNumber: string;
    message: string;
    consent: boolean;
  }) => {
    if (!captchaKey) {
      setWasSendTry(true);
      return;
    }

    setLoading(true);

    axios
      .post(`https://formsubmit.co/ajax/${datoCmsSetting.eMail}`, {
        "Imię ": data.name,
        "E-mail": data.email,
        "Numer telefonu": data.phoneNumber || "Nie podano",
        "Wiadomość ": data.message,
      })
      .then((res) => {
        res.status === 200 ? setSuccessfulSend(true) : setErrorSend(true);
        setLoading(false);
      })
      .catch(() => {
        setErrorSend(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      (errors.message ||
        errors.name ||
        errors.email ||
        errors.consent ||
        errors.phoneNumber) &&
      !captchaKey
    ) {
      setWasSendTry(true);
    }
  }, [errors.message, errors.name, errors.email, errors.consent]);

  return (
    <StyledWrapper id="contact">
      <StyledInnerWrapper>
        <StyledLeftColumn>
          <StyledHeadline>
            Serdecznie zapraszamy do kontaktu z naszym biurem sprzedaży!
          </StyledHeadline>

          <StyledContactBlock
            icon={markerIcon}
            headline="Biuro sprzedaży"
            href={datoCmsSetting.addressLink}
          >
            {datoCmsSetting.address}
          </StyledContactBlock>

          {/* <StyledContactBlock
            icon={phoneIcon}
            headline="Numer kontaktowy"
            href={`tel:${datoCmsSetting.phoneNumber.replace(/\s/g, "")}`}
          >
            {datoCmsSetting.phoneNumber}
          </StyledContactBlock> */}

          <StyledContactBlock
            icon={phoneIcon}
            headline="Numer kontaktowy"
            href={`tel:604960156`}
          >
            +48 604 960 156
          </StyledContactBlock>

          <StyledContactBlock
            icon={phoneIcon}
            headline="Numer kontaktowy"
            href={`tel:607567479`}
          >
            +48 607 567 479
          </StyledContactBlock>

          <StyledContactBlock
            icon={mailIcon}
            headline="Adres e-mail"
            iconSize="1.8rem"
            href={`mailto:${datoCmsSetting.eMail}`}
          >
            {datoCmsSetting.eMail}
          </StyledContactBlock>
        </StyledLeftColumn>
        <StyledRightColumnn>
          <StyledSecondHeadline>Napisz do nas</StyledSecondHeadline>

          {!successfulSend && !errorSend ? (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <StyledInput
                label="Imię i nazwisko"
                error={
                  errors.name ? "Imię i nazwisko jest wymagane." : undefined
                }
                {...register("name", { required: true })}
              />
              <StyledInput
                label="Adres e-mail"
                error={
                  errors.email?.type === "required"
                    ? "E-mail jest wymagany."
                    : errors.email?.type === "pattern"
                    ? "E-mail jest nieprawidłowy."
                    : undefined
                }
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <StyledInput
                label="Numer telefonu"
                {...register("phoneNumber")}
              />
              <StyledTextarea
                label="Treść wiadomości"
                error={errors.message ? "Napisz wiadomość." : undefined}
                {...register("message", { required: true })}
              />

              <StyledReCaptchaWrapper>
                <ReCaptcha
                  sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
                  hl="pl"
                  onChange={(key) => {
                    setCaptchaKey(key);
                    setWasSendTry(false);
                  }}
                />

                {!captchaKey && wasSendTry && (
                  <span>Potwierdź, że nie jesteś robotem</span>
                )}
              </StyledReCaptchaWrapper>

              <StyledBottomFormWrapper>
                <StyledCheckbox
                  label="Wyrażam zgodę na przetwarzanie moich danych
  osobowych zgodnie z ustawą o ochronie danych
  osobowych w związku z wysłaniem zapytania przez
  formularz kontaktowy. Podanie danych jest dobrowolne,
  ale niezbędne do przetworzenia zapytania."
                  error={errors.consent ? "Zgoda jest wymagana." : undefined}
                  {...register("consent", { required: true })}
                />

                <StyledButton icon={arrowRightIcon}>Wyślij</StyledButton>
              </StyledBottomFormWrapper>
            </StyledForm>
          ) : (
            <AfterSendMessage $isSuccess={successfulSend}>
              {successfulSend
                ? "Twoja wiadomość została wysłana pomyślnie"
                : "Przepraszamy, coś poszło nie tak... Spróbuj ponownie później"}
            </AfterSendMessage>
          )}
        </StyledRightColumnn>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      address
      addressLink
      phoneNumber
      eMail
    }
  }
`;

export default Contact;
